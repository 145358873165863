

























































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";
import Utils from "@/common/utils/utils";
import readXlsxFile from "read-excel-file";
import "vuetify/dist/vuetify.min.css";
import { v4 as uuidv4 } from "uuid";

@Component
export default class ManualEmissionsImport extends Vue {
  private errors: string = null;
  private tree: any[] = [];
  private sql: string = null;
  private removeDuplicates: boolean = false;

  private get organisation(): OrganisationDetails {
    return organisationModule.organisationDetails;
  }

  private excelFileChanged(event) {
    this.errors = null;

    readXlsxFile(event.target.files[0]).then((rows) => {
      if (this.validateExcelData(rows)) {
        this.parseExcelData(rows);
      }
    });
  }

  private validateExcelData(rows: any[]): boolean {
    if (rows.length == 0) {
      this.errors = "Filen innehåller inga rader.";
      return false;
    }

    // for (var i = 0; i < rows.length; i++) {
    //   if (rows[i].length != 6) {
    //     this.errors = "Rad " + (i + 1) + " innehåller inte sex kolumner.";
    //     return false;
    //   }
    // }

    return true;
  }

  private generateSql(orgUnits: Array<OrgUnit>) {
    let orgUnitSql = "";
    let costCenterSql = "";
    const insertOrgUnitSql =
      "INSERT INTO [dbo].[OrganisationUnits] ([Id],[OrganisationId],[Name],[AvailableFrom],[AvailableTo],[ParentId]) VALUES ";
    const insertCostCenterSql =
      "INSERT INTO [dbo].[CostCenters] ([Id],[Number],[OrganisationUnitId],[OrganisationId]) VALUES";

    for (var i = 0; i < orgUnits.length; i++) {
      const orgUnit = orgUnits[i];

      orgUnitSql += insertOrgUnitSql;
      if (orgUnit.parentId)
        orgUnitSql += `('${orgUnit.id}','${this.organisation.id}','${orgUnit.name}',null,null,'${orgUnit.parentId}')\r\n`;
      else
        orgUnitSql += `('${orgUnit.id}','${this.organisation.id}','${orgUnit.name}',null,null,null)\r\n`;

      if (orgUnit.costCenters) {
        for (var n = 0; n < orgUnit.costCenters.length; n++) {
          costCenterSql += insertCostCenterSql;
          costCenterSql += `('${uuidv4()}',${orgUnit.costCenters[n]},'${
            orgUnit.id
          }','${this.organisation.id}')\r\n`;
        }
      }
    }

    this.sql = orgUnitSql + "\r\n" + costCenterSql;
  }

  private parseExcelData(rows) {
    const maxLevels = 8;
    const orgUnits = Array<OrgUnit>();

    const orgUnitRoot = {
      id: uuidv4(),
      parentId: null,
      name: rows[0][1],
      level: 0,
    } as OrgUnit;
    orgUnits.push(orgUnitRoot);

    for (var row = 0; row < rows.length; row++) {
      let costCenters = new String(rows[row][0]).split(",").map((element) => {
        return Number(element);
      });

      const names = [];

      for (var col = 1; col < maxLevels + 1; col++) {
        const name = rows[row][col];
        if (name) {
          if (this.removeDuplicates) {
            if (col > 1 && name === rows[row][col - 1]) {
              break;
            }
          }

          names.push(name);
        } else {
          break;
        }
      }

      let parentId = orgUnitRoot.parentId;

      for (var i = 0; i < names.length; i++) {
        let orgUnit = orgUnits.find((x) => x.name == names[i] && x.level == i);

        if (!orgUnit) {
          orgUnit = {
            id: uuidv4(),
            parentId: parentId,
            name: names[i],
            level: i,
            costCenters: i == names.length - 1 ? costCenters : null,
          };
          orgUnits.push(orgUnit);
        }

        parentId = orgUnit.id;
      }
    }

    this.tree = this.createDataTree(orgUnits);

    this.generateSql(orgUnits);
  }

  private createDataTree(orgUnits: OrgUnit[]): any[] {
    const hashTable = Object.create(null);
    orgUnits.forEach(
      (aData) => (hashTable[aData.id] = { ...aData, children: [] })
    );
    const dataTree = [];
    orgUnits.forEach((aData) => {
      if (aData.parentId)
        hashTable[aData.parentId].children.push(hashTable[aData.id]);
      else dataTree.push(hashTable[aData.id]);
    });
    return dataTree;
  }

  private async importEmissions() {
    //await adminService.importManualEmissions(this.organisation.id, this.batch);
  }

  private activated() {}
}

interface OrgUnit {
  id: string;
  parentId: string;
  name: string;
  level: number;
  costCenters: Array<number>;
}
